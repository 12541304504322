<template>
    <div class="report-box">
        <div class="report-contect">
             <p class="report-title">Report Settings</p>
             <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">Check All</el-checkbox>
            <el-checkbox-group v-model="checkedPage"  @change="handleCheckedCitiesChange">
                <el-checkbox  v-for="(item,index) in data" :label="item.id" :key="index" :disabled = "item.name==='Cover Page'||item.name==='Table of Contents'">{{item.name}}</el-checkbox>
            </el-checkbox-group>
            <div class="price">
              <div class="row suggest-row">
                <span>Suggested List Price</span>
                <el-switch v-model="value1" active-color="#678993" inactive-color="#dcdfe6"  inactive-text="Display as range"></el-switch>
              </div>
              <el-form :model="form" ref="formdata" :rules="rule">
              <div v-if="value1 === true" style="margin-top:20px;">
                  <div class="row">
                  <el-form-item prop='low_price' :rules="rule.require">
                    <p class="price-title">Low Price</p>
                    <el-input v-model="form.low_price" @input="e=>form.low_price = changePrice(e)"  >
                      <i slot="prefix" class="el-input__icon">$</i>
                    </el-input>
                  </el-form-item>
                  <el-form-item prop="high_price" :rules="rule.require" style="margin-left:20px;">
                    <p class="price-title">High Price</p>
                    <el-input v-model="form.high_price"  @input="e=>form.high_price = changePrice(e)" > 
                      <i slot="prefix" class="el-input__icon">$</i>
                    </el-input>
                  </el-form-item>
                  </div>
              </div>
              <div v-if="value1 === false" style="margin-top:20px;">
                <el-form-item prop="price" :rules="rule.require">
                <p class="price-title">Price</p>
                <el-input v-model="form.price" @input="e=>form.price = changePrice(e)"  >
                  <i slot="prefix" class="el-input__icon">$</i>
                </el-input>
                </el-form-item>
              </div>
               </el-form>
              <p style="margin:0;"><el-switch v-model="value2" active-color="#678993" inactive-color="#dcdfe6" inactive-text="Display $/sf Price"></el-switch></p>
             <div v-show="value2===true" style="font-weight: 600;">
              <p v-if="value1===false"><span >{{accountingPrice[0] | currencyPrice("$ ",0)}}/SF</span></p>
              <p v-else><span>{{accountingPrice[0] | currencyPrice("$ ",0)}}/SF - {{accountingPrice[1] | currencyPrice("$ ",0)}}/SF</span></p>
             </div>
              
            </div>
            <div class="btn-row row">
              <p class="btn-box row" @click="prev"><i class="el-icon-back"></i><span>Previous</span></p>
              <p style="text-align: left;margin-right:20px;"><el-button @click="saveSettingsReview" :loading="loading">Save Settings and Review CMA</el-button></p>
              <p style="text-align: left;"><el-button @click="downloadSeparatePages" :loading="loadings">Download as Separate Pages</el-button></p>
            </div>
        </div>
    </div>
</template>
<script>
import { apiCreateLinkPDF, apiGetLinkListings } from '../../API/api';
import { Minixs } from "../../js/mixins";
export default {
  mixins: [Minixs],
    data(){
        return {
          loading:false,
          loadings:false,
            isIndeterminate: false,
            checkAll: true,
            checkedPage:["Cover Page","Poem","Table of Contents","Meet Your Brokers","Marketing","What is a CMA","Comparable Properties","Comparable Property","Analysis","Pricing Your Property","Home Selling Process","Closing Costs",],
            data:[
              {id:"Cover Page",name:"Cover Page"},
              {id:"Poem",name:"Poem"},
              {id:"Table of Contents",name:"Table of Contents"},
              {id:"Meet Your Brokers",name:"Meet Your Brokers"},
              {id:"Marketing",name:"Marketing"},
              {id:"What is a CMA",name:"What is a CMA"},
              {id:"Comparable Properties",name:"Subject Property"},
              {id:"Comparable Properties",name:"Comparable Properties(summary)"},
              {id:"Comparable Property",name:"Comparable Property(detail)"},
              {id:"Analysis",name:"Analysis"},
              {id:"Pricing Your Property",name:"Pricing Your Property"},
              {id:"Home Selling Process",name:"Home Selling Process"},
              {id:"Closing Costs",name:"Closing Costs"},
              // {id:"Next Steps",name:"Next Steps"},
            ],
            value1:false,
            value2:false,
            form:{}, 
            rule:{require:[{required:true,message:"this information is required",tigger:'blur'}]},
            sf_price:[],
            link:{},
            price:[],
        }
    },
    computed:{
      accountingPrice(){
        let num1 = "";
        let num2 = "";
        let arr = [];
        if(this.value1 === false){
          if(this.form.price){
          let p = this.form.price.split(",").join("");
           num1 = Math.round(p / this.link.LotSizeSquareFeet);
           arr = [num1];
           this.price = [p]
           }
          }else {
            if(this.form.low_price&&this.form.high_price){
            let lp =this.form.low_price.split(",").join("");
            let hp =this.form.high_price.split(",").join("");
           num1 = Math.round(lp / this.link.LotSizeSquareFeet);
           num2 = Math.round(hp / this.link.LotSizeSquareFeet);
           this.price = [lp,hp]
           arr = [num1,num2];
           }else if(!this.form.low_price&&this.form.high_price){
            let hp =this.form.high_price.split(",").join("");
           num2 = Math.round(hp / this.link.LotSizeSquareFeet);
           this.price = [hp]
           arr = [num2];
           }else if(this.form.low_price&&!this.form.high_price){
              let lp =this.form.low_price.split(",").join("");
            num1 = Math.round(lp / this.link.LotSizeSquareFeet);
            this.price = [lp]
            arr = [num1];
           }
          }
          if(this.value2){
            if(this.link.LotSizeSquareFeet){
              this.sf_price = arr;
              return arr
            }
          }
          return [];
         
      }
    },
    methods:{
       handleCheckAllChange(val) {
         let arr=[]
        this.data.forEach(item => {
          arr.push(item.id)
        });
         let obj ={};
          arr= arr.reduce((cur,next) => { obj[next] ? "" : obj[next] = true && cur.push(next);
            return cur;
          },[]) 
        this.checkedPage = val ? arr : [];
        this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.data.length;
        this.isIndeterminate = checkedCount > 0 && checkedCount < this.data.length;
      },
      download(val){
         this.$refs.formdata.validate((valid) => {
          if (valid) {
             let arr =[];
            arr = this.$store.state.linksAllId;
            if(arr&&arr.length!=0){
              let obj ={};
              arr= arr.reduce((cur,next) => { obj[next] ? "" : obj[next] = true && cur.push(next);
                return cur;
              },[]) //设置cur默认类型为数组，并且初始值为空的数组LotSizeSquareFeet
            };
            if(val) {this.loadings = true;} else { this.loading = true;}
           
            apiCreateLinkPDF({ half: val,sf_prices:this.sf_price,prices:this.price,selected_listings:arr&&arr.length!=0?arr:[],listing:this.$route.params.id,settings:this.checkedPage,sale_listings:this.$store.state.sale_listings&&this.$store.state.sale_listings.length!=0?this.$store.state.sale_listings:[],competitive_listings:this.$store.state.competitive_listings&&this.$store.state.competitive_listings.length!=0?this.$store.state.competitive_listings:[]}).then(res=>{
             let blob = new Blob([res.data], { type: res.data.type })
             let url = URL.createObjectURL(blob);
             this.$emit("updateTab","")
             this.$store.commit('setCmaPDFUrl', url);
             this.$router.push({name:"review-share",params:this.$route.query.id})
            }).finally(()=>{ this.loading = false;this.loadings = false;})
          } else {
            return false;
          }
        });
      },
      downloadSeparatePages(){
        this.download(true)
      },
      saveSettingsReview(){
         this.download(false)
      },
      prev(){ 
         const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.2)'
        });
        setTimeout(() => {
          loading.close();
        }, 1000);
        this.$emit("updateTab","/competitve-listings")
        this.$router.push({name:"competitve-listings",params:this.$route.query.id});
         },
    },
    created(){
       if (this.$route.params.id) {
        apiGetLinkListings({ link_id: this.$route.params.id }).then((res) => {
         this.link = res.results[0];
      });
      }
    }
}
</script>
<style lang="scss" scoped>
.report-box{
    .report-contect{ padding: 20px 20px 160px 20px;position: relative;
    .el-button{background-color: #678993;color: #fff;}
    &::after{
        content: ""; position: absolute;bottom: 0;left:0;width: 100%;border-bottom:1px solid #E1E9EF;
    }}
    /deep/ .el-input__icon{ font-style: normal;color: #555;}
    .report-title{text-align: left;font-size: 18px; font-family: 'Roboto-Bold';}
    /deep/ .el-checkbox{width: 100%;text-align: left;line-height: 30px;}
    .price{ margin-top: 20px;text-align: left;
        /*在谷歌下移除input[number]的上下箭头*/
        /deep/ input[type="number"]::-webkit-outer-spin-button,
        /deep/ input[type="number"]::-webkit-inner-spin-button {
          -webkit-appearance: none !important;
          margin: 0;
        }
        /*在firefox下移除input[number]的上下箭头*/
        /deep/ input[type="number"] {
          -moz-appearance: textfield;
        }
       .suggest-row{span{display: inline-block;margin-right: 20px;font-size: 20px;}}
       .price-title{margin: 0;line-height: 20px;text-align: left;}
       /deep/ .el-switch__label.is-active{ color: #2c3e50;}
       /deep/ .el-input{width: 300px;}
    }
  .btn-row{display: flex;-webkit-justify-content: flex-start;justify-content: flex-start;padding: 20px 0;
    .btn-box{width: 95px;background-color: #E2EFF6;height: 36px;margin-right: 20px;line-height: 36px;color: #38425B;justify-content: space-between;padding: 0 20px;border-radius: 2px; cursor: pointer;}
    // .back{background-color: #E2EFF6; color: #38425B;margin-right: 20px;}
  }
}
</style>